import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_2 = { class: "text-gray-500" }
const _hoisted_3 = { class: "font-semibold" }
const _hoisted_4 = { class: "flex justify-start space-x-2 w-full" }
const _hoisted_5 = { class: "flex space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_ClearButton = _resolveComponent("ClearButton")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _directive_allow = _resolveDirective("allow")!

  return (!_ctx.loading)
    ? (_openBlock(), _createBlock(_component_BaseCard, {
        key: 0,
        class: "space-y-8"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('custom.uhb.escalation-email.alert-notifications')), 1),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('custom.uhb.escalation-email.instructions')), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('custom.uhb.escalation-email.recipients')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.escalationEmails, (email, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_BaseTextInput, {
                  modelValue: _ctx.escalationEmails[index],
                  "onUpdate:modelValue": ($event: any) => ((_ctx.escalationEmails[index]) = $event),
                  "data-dusk": "escalation-email-input",
                  placeholder: _ctx.$t('custom.uhb.escalation-email.enter-email'),
                  class: "w-full",
                  error: _ctx.getError(index)
                }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "error"]),
                _createVNode(_component_ClearButton, {
                  "data-dusk": "clear-email-button",
                  onClick: ($event: any) => (_ctx.removeEmail(index))
                }, null, 8, ["onClick"])
              ])
            ]))
          }), 128)),
          _createVNode(_component_BaseButton, {
            color: "gray",
            "left-icon": "add",
            onClick: _ctx.addNewEscalationEmailAddress
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('custom.uhb.escalation-email.add-email')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
              "data-dusk": "escalation-email-address-save",
              onClick: _ctx.saveChanges
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.common.save-changes')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])), [
              [_directive_allow, 'org-unit:update']
            ]),
            _createVNode(_component_BaseButton, {
              color: "gray",
              onClick: _ctx.cancel
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}